<template>
  <div class="convenience">
    <div class="search-container">
      <el-row>
        <el-col :span="12" v-if="false">
          <div class="input-wrapper">
            <el-input
              @keyup.enter.native="searchData"
              placeholder="请输入关键字"
              v-model="form.searchValue"
            ></el-input>
            <img
              @click="searchData"
              class="input-image"
              src="../../assets/images/input_search.png"
              alt=""
              srcset=""
            />
          </div>
        </el-col>
        <el-col :span="12">
          <div class="btn-wrapper">
            <el-button v-if="false" @click="cleanForm" class="clean-button">{{
              $t("clean")
            }}</el-button>
            <el-button @click="changeAddDialog(true)">{{
              $t("add")
            }}</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight"
        style="width: 100%"
        border
        @sort-change="sort_post_change"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          prop="deviceCode"
          min-width="115"
          align="center"
          label="设备ID"
        >
        </el-table-column>
        <el-table-column
          prop="deviceName"
          align="center"
          min-width="200"
          label="设备名称"
        >
        </el-table-column>
        <el-table-column
          prop="deviceUnit"
          align="center"
          min-width="115"
          label="设备型号"
        ></el-table-column>
        <el-table-column
          prop="venueName"
          min-width="200"
          align="center"
          label="场馆"
        ></el-table-column>
        <el-table-column
          prop="sportsProjectName"
          min-width="120"
          align="center"
          label="运动项目"
        ></el-table-column>
        <el-table-column
          prop="spaceName"
          min-width="120"
          align="center"
          label="场地"
        ></el-table-column>
        <el-table-column
          prop="location"
          min-width="200"
          align="center"
          label="安装地点"
        ></el-table-column>
        <el-table-column
          prop="online"
          align="center"
          min-width="115"
          label="在线状态"
        >
          <template slot-scope="scope">
            <div :class="scope.row.online === '1' ? 'onLine' : 'offLine'">
              {{ scope.row.online === "1" ? "在线" : "离线" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="false"
          prop=""
          min-width="115"
          align="center"
          label="检测人数"
        ></el-table-column>
        <el-table-column
          prop="display"
          min-width="115"
          align="center"
          label="是否地图显示"
        >
          <template slot-scope="scope">
            <div :class="scope.row.display === 'Y' ? 'onLine' : 'offLine'">
              {{ scope.row.display === "Y" ? "是" : "否" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="insertTime"
          align="center"
          min-width="200"
          label="添加时间"
        ></el-table-column>
        <el-table-column label="操作" min-width="168" align="center">
          <template slot-scope="scope">
            <span
              class="oper-text"
              @click="changeAddDialog(true, true, scope.row)"
              >{{ $t("edit") }}</span
            >
            <span class="line"></span>
            <span
              class="oper-text"
              @click="openDeleteDialog(scope.row.id, 1)"
              >{{ $t("delete") }}</span
            >
            <span class="line"></span>
            <span
              class="oper-text"
              @click="openDeleteDialogNew(scope.row.id, 2)"
              >{{ $t("report_details") }}</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-content">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      ></el-pagination>
    </div>
    <!-- 添加/修改弹窗 -->
    <add-dialog
      ref="addDialog"
      :Language="Language"
      :show="addDialog.visible"
      :isEdit="addDialog.isEdit"
      @sureDialog="sureConfirmDialog"
      @closeDialog="changeAddDialog"
    ></add-dialog>
    <!-- 删除确认弹窗 -->
    <confirm-dialog
      ref="confirmDialog"
      :Language="Language"
      :show="confirmDialog.visible"
      :type="confirmDialog.type"
      @sureDialog="sureConfirmDialog"
      @closeDialog="changeConfirmDialog"
    ></confirm-dialog>
  </div>
</template>
<script>
import mixin from "@/mixins/index";
import apis from "@/apis";
import { mapState } from "vuex";
import addDialog from "./dialog/addDialog.vue";
import confirmDialog from "@/components/confirmDialog.vue";
import pageClass from "@/unit/pageClass";
import createDeleteMixin from "@/mixins/createDeleteMixin.js";

class IndexClass extends pageClass {
  constructor(type) {
    super();
    if (type === "form") {
      this.searchValue = "";
    }
  }
}

export default {
  mixins: [mixin, createDeleteMixin(apis.fitnessDeviceManage)],
  components: { addDialog, confirmDialog },
  data() {
    return {
      sportsProjectList: [],
      venueName: "",
      form: new IndexClass("form"), // 实例化一个表单的变量
      tableData: [],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      confirmDialog: {
        visible: false,
        type: 1, //1:删除 9：发布
        data: {},
      },
    };
  },
  mounted() {},
  activated() {},
  computed: {
    ...mapState(["Language", "DROPDOWNBOX", "TOKEN", "venueId"]),
  },
  methods: {
    // 删除
    modelDelete() {
      const formData = {
        id: this.confirmDialog.data.id,
      };
      this.$http
        // .delete(`${apis.delMontiorDevice}?id=${this.confirmDialog.data.id}`)
        .delete(apis.delMontiorDevice, { params: formData })
        .then((res) => {
          if (res.data.code === 0) {
            this.$refs.confirmDialog.closeDialog();
            this.$message.success("删除成功!");
            this.searchData();
          }
        });
    },
    // 清空按钮
    cleanForm() {
      // this.$refs.form.resetFields();
      this.form = new IndexClass("form");
      this.$nextTick(() => {
        this.searchData();
      });
    },
    // 查询
    searchData() {
      this.$http
        .post(apis.getBodyMonitorList, {
          pageNum: this.form.pageNum,
          pageSize: this.form.pageSize,
        })
        .then((res) => {
          if (res.data.code === 0) {
            console.log("获取体质监测仪数据：", res.data);
            this.tableData = res.data.rows;
            this.form.total = res.data.total;
          }
        });
    },
    // 修改数据回显
    changeAddDialog(dialogStatus, isEdit = false, data = {}) {
      if (dialogStatus) {
        this.addDialog.isEdit = isEdit;
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.addDialog.data = data;
          this.$refs["addDialog"].getDataQuery(data);
        }
      } else {
        this.searchData();
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus;
      });
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type;
      this.confirmDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.confirmDialog.data.id = "";
        this.searchEvent();
      }
    },
    /**
     * 删除和发布点击确认
     * id: 对应的id值
     * type 1：删除 9:发布
     */
    /* eslint-disable */
    openDeleteDialog(id, type) {
      this.confirmDialog.type = type;
      this.confirmDialog.data.id = id;
      this.changeConfirmDialog(true, type);
    },
    openDeleteDialogNew(id, type) {
      if (type === 2) {
        this.$message.success("敬请期待!");
      }
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      if (this.confirmDialog.type === 1) {
        this.modelDelete();
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/styles/mixin.scoped.less";
</style>
<style lang="less" scoped>
@import "~@/assets/styles/mixin.less";
.convenience {
  .search-container {
    .input-wrapper {
      width: 280px;
      position: relative;
      .input-image {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }
  .page-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 20px;
  }
  .el-tabs__active-bar {
    display: none;
  }
}

.allOff-wrapper {
  display: inline-block;
  margin-right: 38px;
  .allAndOff {
    margin-right: 10px;
  }
}
.btn-wrapper {
  display: inline-block;
  margin-right: 32px;
}
.oper-text {
  color: #439bff;
  font-size: 12px;
  cursor: pointer;
}
.line {
  display: inline-block;
  margin: 0 8px;
  width: 1px;
  height: 13px;
  background: #d8d8d8;
}
.onLine {
  color: #6dd400;
}
.offLine {
  color: #e02020;
}
</style>
