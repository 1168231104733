<!--
 * @Author: your name
 * @Date: 2020-03-23 11:19:40
 * @LastEditTime: 2020-09-27 15:41:15
 * @LastEditors: zhongtao
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\venue\dialog\addDialog.vue
 -->
<template>
  <div class="dialog-container">
    <el-dialog
      :title="`${isEdit ? '修改设备' : '新增设备'}`"
      :visible.sync="show"
      @opened="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="50%"
    >
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="场馆名称" prop="venueName" disabled>
              <el-select v-model="form.venueName" style="width:100%" @change="getVenueId($event)">
                <el-option v-for="item in venueNameList" :key="item.id" :label="item.venueName" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="运动项目" prop="sportsProjectName">
              <el-select v-model="form.sportsProjectName" style="width:100%" @change="getSportId($event)">
                <el-option v-for="item in sportsProjectList" :key="item.sportsProjectCode" :label="item.sportsProjectName" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="场地" prop="spaceName">
              <el-select v-model="form.spaceName" style="width:100%" @change="getSpaceId($event)">
                <el-option v-for="item in spaceList" :key="item.spaceCode" :label="item.spaceName" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备ID" prop="deviceCode">
              <el-input clearable v-model="form.deviceCode" placeholder="请输入" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备名称" prop="deviceName">
              <el-input clearable v-model="form.deviceName" placeholder="请输入" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备型号" prop="deviceUnit">
              <el-input clearable v-model="form.deviceUnit" placeholder="请输入" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
           <el-col :span="24">
            <el-form-item label="安装地点" prop="location">
              <el-input clearable v-model="form.location" placeholder="请输入" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
           <el-col :span="24">
            <el-form-item label="地图显示" prop="display">
                <el-select v-model="form.display">
                  <el-option :value="'Y'" label="是"></el-option>
                  <el-option :value="'N'" label="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <div class="footer-confirm" @click="onSave">{{$t('sure')}}</div>
        <div class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import mixin from "@/mixins/dialogMixin";
import twoStepDialogMixin from "@/mixins/twoStepDialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";

export default {
  mixins: [mixin, twoStepDialogMixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  mounted() {
    console.log("初始化表单", this.form)
    console.log("DROPDOWNBOX.VENUE_SERVICE", this.DROPDOWNBOX)
  },
  data() {
    return {
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
      venueNameList: [],
      sportsProjectList: [],
      spaceList: [],
    };
  },
  activated() {
    this.$http.get(apis.getVenue).then((res) => {
      if (res.data.code === 0) {
        this.venueNameList = res.data.data
      }
    });
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN", "venueList"]),
  },
  methods: {
    getVenueId(item) {
      this.sportsProjectList = []
      this.form.sportsProjectCode = ''
      this.form.sportsProjectName = ''
      this.spaceList = []
      this.form.spaceCode = ''
      this.form.spaceName = ''
      this.form.venueId = item.id
      this.form.venueName = item.venueName
      this.getSportsProject(this.form.venueId)
    },
    getSportsProject(e) {
      const paraData = {
        venueId: e,
      }
      this.$http.get(apis.getSportsProject, {
        params: paraData,
      }).then((res) => {
        if (res.data.code === 0) {
          this.spaceList = []
          if (res.data.data.length > 0) {
            this.sportsProjectList = res.data.data
          }
        }
      });
    },
    getSportId(item) {
      if (!this.form.venueId) {
        this.$message.success("请先选择场馆");
        return
      }
      this.spaceList = []
      this.form.spaceCode = ''
      this.form.spaceName = ''
      this.form.sportsProjectCode = item.sportsProjectCode
      this.form.sportsProjectName = item.sportsProjectName
      this.getSpaceInfo(this.form.sportsProjectCode)
    },
    getSpaceInfo(e) {
      const paraData = {
        venueId: this.form.venueId,
        sportsProjectCode: e,
      }
      this.$http.get(apis.getSpaceInfo, {
        params: paraData,
      }).then((res) => {
        // this.spaceList = res.data.data
        this.spaceList = []
        if (res.data.code === 0) {
           if (res.data.data.length > 0) {
              this.spaceList = res.data.data
           }
        }
        // else if (res.data.code === 500) {
        //   console.log("res", res.data.code)
        //   this.$message.success("暂无场地信息");
        // }
      });
    },
    getSpaceId(item) {
      console.log("this.form===", this.form)
      this.form.spaceCode = item.spaceCode
      this.form.spaceName = item.spaceName
    },
    /**
     * @function 关闭弹窗
     * @description 先初始化，然后传值到组件外部进行关闭
     **/
    async closeDialog() {
      await this.init()
      // this.$refs.bMap.clearData();
      this.$emit('closeDialog', false)
    },
    ...mapMutations(['setVenueId', "setVenueList"]),
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.form = new AddDialogClass("form");
      console.log("我初始化了");
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    onSave() {
      // 这里写业务需求
      console.log("点击编辑时", this.form)
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isEdit) {
            console.log("dataForm===>编辑前", this.form)
            const dataForm = {
              venueName: this.form.venueName,
              sportsProjectCode: this.form.sportsProjectCode,
              sportsProjectName: this.form.sportsProjectName,
              deviceCode: this.form.deviceCode,
              deviceName: this.form.deviceName,
              deviceUnit: this.form.deviceUnit,
              location: this.form.location,
              display: this.form.display,
              online: this.form.online,
              spaceCode: this.form.spaceCode,
              spaceName: this.form.spaceName,
              venueId: this.form.venueId,
              id: this.form.id,
            }
            console.log("dataForm===>编辑后", dataForm)
            this.$http.post(apis.updateBodyMonitor, { ...dataForm })
            .then((res) => {
              if (res.data.code === 0) {
                this.closeDialog();
                this.$message.success("修改成功!");
              }
            })
          } else {
            const addData = {
              ...this.form,
            };
            console.log("添加的数据", addData)
            this.$http.post(apis.addBodyMonitor, { ...addData })
            .then((res) => {
              if (res.data.code === 0) {
                this.closeDialog();
                this.$message.success("添加成功!");
              }
            })
          }
        }
      })
    },
    getDataQuery(data) {
      console.log("data", data)
      this.form = Object.assign({}, data)
      console.log("回显数据", this.form)
      this.getSportsProject(this.form.venueId)
      this.getSpaceInfo(this.form.sportsProjectCode)
    },
    openDialog() {
      // this.form.venueName = this.Name;
    },
  },
};
</script>
<style lang="less" scoped>
.footer-confirm,.footer-cancel{
  line-height: 32px !important;
}
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color:rgba(168,168,168,1);
  font-size:12px;
  margin-top: -15px;
}
.bmap-wrap {
  width: 100%;
  height: 300px;
}
.image-wrap{
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>
<style lang="less">
@import "~@/assets/styles/mixin.less";

.service-wrapper {
  .el-checkbox {
    min-width: 80px !important;
  }
}
</style>
